import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'

const PomnikWLadzyniuPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Pomnik Mieszkańców Stanisławowa poległych w latach 1914-1920" />
      <ReturnToPrevPage to={'/gminy/stanislawow'}>
        Materiały dotyczące gminy Stanisławów
      </ReturnToPrevPage>
      <Header1>
        Pomnik Mieszkańców Stanisławowa poległych w latach 1914-1920
      </Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Z inicjatywy pochodzącego ze Stanisławowa kapitana Józefa Boruckiego,
          na tamtejszym Rynku Piaskowym ufundowano i 15 sierpnia 1938 roku
          odsłonięto pomnik poświęcony pamięci Mieszkańców gminy Stanisławów
          poległych w walkach o Niepodległość Ojczyzny w latach 1914-1920. Po
          likwidacji rynku pomnik znajdował się na prywatnej posesji, z której
          został w latach osiemdziesiątych przeniesiony na obecne miejsce, czyli
          przed budynek Gminnego Ośrodka Kultury w Stanisławowie. Obecny kształt
          pomnik zyskał podczas rekonstrukcji w 2008 roku.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/stanislawow/pomnik-gok/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/stanislawow/pomnik-gok/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PomnikWLadzyniuPage
